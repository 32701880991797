import './work.scss';
import DownloadSVG from './../../assets/logos/download-btn.svg';

const Work = () => {
  return (
    <>
      <div className='work__container col-md-6 col-10'>
        <h3 className='work__title'>Frontend Développeur </h3>
        {/* <section className='techs'>
          <span className="tech-tag">#Angular</span>
          <span className="tech-tag">#Typescript</span> 
          <span className="tech-tag">#JEST</span>
        </section> */}
        <ul>
          <li>Mise à jour des règles typescript pour un code plus encadré</li>
          <li>Multiplication de l'utilisation des interfaces pour l'interdépendance des couches</li>
          <li>Ecriture des documentations sur la nouvelle stack</li>
          <li>Optimisation du code pour plus de lisibilité</li>
        </ul>
        <ul>
          <li>Tests unitaires sur la totalité des couches <span className="tech-tag">JEST</span>  </li>
          <li>Création des outils nécessaires pour la facilitation des tâches futurs</li>
        </ul>
      </div>
    </>
  )
}

const Works = () => {

  return (
    <>
      <span><img src={DownloadSVG} className={'btn-download'} alt='Redirect to Twitter'/></span>
      <div className='works'>
        <Work />
        <Work />
        <Work />
        <Work />
      </div>
    </>
  )
}

export default Works;