import { RefObject, useRef, useState } from 'react';
import './contact.scss';
import emailjs from '@emailjs/browser';
import emailConf from '../../mail.conf.json';

/**
 * Validation mail JS
 * Notification email successfully sent or error
 */

const SuccessSVGLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
    <path d="M8 0a8 8 0 1 0 8 8A8 8 0 0 0 8 0zm3.97 4.97L7.5 9.439 5.529 7.468a.75.75 0 1 1 1.06-1.06L7.5 7.939l4.439-4.44a.75.75 0 0 1 1.06 0c.293.293.293.767 0 1.06z"/>
  </svg>
);

const SendingEmailLogo = () => (
  <>
    <svg width="20" height="20" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 14L13 21L20 4L3 11L6.5 12.5" stroke="orangered" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </>
)

const Contact = () => {
  const [ showSuccessNotification, setShowSuccessNotif ] = useState(false);
  const [ isLoading, setLoading ] = useState(false);
  const form: RefObject<HTMLFormElement> | any = useRef();
  const successEmailSentMessage = 'Email sent';
  const loadingMessage = 'Loading...';

  const sendEmail = (e: any) => {
    e.preventDefault();
    setLoading(true)

    const { SERVICE_ID, TEMPLATE_ID, PUBLIC_KEY } = emailConf;

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY)
      .then(() => {
        setLoading(false);
        setShowSuccessNotif(true);
         setTimeout(() => {
        setShowSuccessNotif(false);
        }, (3000));
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  };

  return (
    <>
      <div className='bg'>CONTACT</div>
      <div className="contact col-md-10 col-10">
        <div className="container">
          <h3 className={'title'}>Contact us.</h3>
          <p className={'subtitle'}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum doloribus natus est molestiae illum.</p>
          <form ref={form} onSubmit={sendEmail}>
            <div className="form-group">
              <input type="email" className={`input`} name={'email'} aria-describedby="emailHelp" placeholder="Enter email" />
              <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
            </div>
            <div className="form-group">
              <textarea className={`input`} aria-describedby="emailHelp" placeholder="Enter email" name={'subject'} />
            </div>
            <button type="submit" className="btn-submit">Send an e-mail</button>
          </form>
        </div>
        <div id="notification" className={`notification notification--success ${(showSuccessNotification ? ' show' : '')}`}>
            <SuccessSVGLogo />
            <span className="notification-text">{successEmailSentMessage}</span>
        </div>
        <div id="notification" className={`notification notification--in-progress ${(isLoading ? ' show' : '')}`}>
            <SendingEmailLogo />
            <span className="notification-text">{loadingMessage}</span>
        </div>
      </div>
    </>
  )
}

export default Contact;