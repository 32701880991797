import './about.scss';
import ProfileImg from '../../assets/images/profile-2.jpg';

const About = () => {

  return (
    <div className='about'>
        {/* <div className='title'>About</div>
        <div className='about__container col-md-8 col-8'>
          <div className="img-container">
            <img src={ProfileImg} alt="" />
          </div>

          <p>Lorem ipsum dolor sit amet <a className={'link'} href="https://angular.io/" target='_blank'> Angular</a>,
            <a className={'link'} href="https://angular.io/" target='_blank'> Typescript</a> & <a className={'link'} href="https://angular.io/" target='_blank'> Javascript </a>
            adipisicing elit. Soluta culpa, nobis explicabo vitae laudantium odit blanditiis ullam nesciunt sed magni ipsam? 
            Officia commodi sit, et impedit illo officiis quo hic?
          </p>
        </div> */}

        <div className="about__img-container">
          <img src={ProfileImg} alt="" />
          <span>ABOUT ME</span>
        </div>
        <div className="about__content">
          <span className="about__title">
              About
          </span>
          <section className="description">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa facilis necessitatibus ipsum veritatis sequi ad consequatur odit corporis dolorum doloremque, ullam sed atque. Qui cupiditate excepturi aliquid rem perspiciatis ipsa!
            </p>
            <br />
            <span className="ctas">
              <button className="btn btn-cv-download">
                Download CV
              </button>
              <button className="btn btn-contact">
                Contact me
              </button>
            </span>
            <br />
            <br />
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
          </section>
        </div>
    </div>
  )
}

export default About;