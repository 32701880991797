import './home.scss';
import { Link } from 'react-router-dom';
import linkedinLogo from '../../assets/logos/icons8-linkedin.svg';
import githubLogo from '../../assets/logos/icons8-github.svg';
import skypeLogo from '../../assets/logos/icons8-skype.svg';
import whatsappLogo from '../../assets/logos/icons8-whatsapp.svg';
import emailLogo from '../../assets/logos/icons8-email.svg';
import arrowDown from '../../assets/logos/arrow-down.svg';
import getInTouch from '../../assets/logos/icons8-get-in-touch.svg';
import Contact from '../Contact/Contact';
import { useInView } from 'react-intersection-observer';
import About from '../About/About';
import Carousel from '../Utils/carousel/Carousel';

const images = [
  'https://images.unsplash.com/photo-1692513873777-40ec199fc071?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80',
  'https://images.unsplash.com/photo-1692613018990-04251332c639?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80',
  'https://images.unsplash.com/photo-1692374227159-2d3592f274c9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80',
  'https://images.unsplash.com/photo-1692613019072-dc52b7da036b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=380&q=80',
]

export const SocialNetwork = () => {
  return (
    <nav className="social d-none d-md-block d-lg-block d-xl-block">
      <span className="social__subtitle">CONTACT</span>
      <ul className='first'>
        <li><Link to="/" target='_blank'><img src={githubLogo} alt='Redirect to Facebook'/></Link></li>
      </ul>
      <ul>
        <li><Link to="/" target='_blank'><img src={skypeLogo} alt='Redirect to Skype'/></Link></li>
        <li><Link to="https://www.linkedin.com/in/rova-ranaivo/" target='_blank'><img src={linkedinLogo} alt='Redirect to linkedin'/></Link></li>
        <li><Link to="/" target='_blank'><img src={emailLogo} alt='Redirect to Email'/></Link></li>
        <li><Link to="/" target='_blank'><img src={whatsappLogo} alt='Redirect to Whatsapp'/></Link></li>
        <li><Link to="/" target='_blank'><img src={arrowDown} alt='Redirect to Twitter'/></Link></li>
        <li><Link to="#contact"><img src={arrowDown} alt='Redirect to Twitter'/></Link></li>
      </ul>
    </nav>
  )
}

const Home = () => {

  const { ref, inView } = useInView({
    // triggerOnce: true, 
    threshold: 0.3
  })
  return (
    <>
      <h1>Nemany e...</h1>
    </>
  );
}

export default Home;